<template>
  <UiPopup
    :model-value="modelValue"
    title="Try again"
    description="Try to reach the lead from alternative number"
    primary-button-text="Call again"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  />
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const submit = () => {
  emits('input')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
